export const BOT_HISTORY = "botHistory";

export const FAQ_BOT_HISTORY = "faqBotHistory";

export const REVIEWS_BOT_HISTORY = "reviewsBotHistory";

export const CONNECTION_ESTABLISHED = {
  id: "001",
  type: "Discoverist-Connection-Established",
};
export const OPEN_BUTTON_CLICKED = {
  id: "002",
  type: "Discoverist-Maximize-Button-Clicked",
};
export const CLOSED_BUTTON_CLICKED = {
  id: "003",
  type: "Discoverist-Minimize-Button-Clicked",
};
export const REDIRECT = {
  id: "004",
  type: "Discoverist-Product-Clicked",
};
export const ADD_TO_CART = {
  id: "005",
  type: "Discoverist-Add-To-Cart-Clicked",
};
export const FAQ_OPEN_BUTTON_CLICKED = {
  id: "006",
  type: "Faq-Maximize-Button-Clicked",
};
export const FAQ_CLOSED_BUTTON_CLICKED = {
  id: "007",
  type: "Faq-Minimize-Button-Clicked",
};
export const REVIEWS_OPEN_BUTTON_CLICKED = {
  id: "008",
  type: "Reviews-Maximize-Button-Clicked",
};
export const REVIEWS_CLOSED_BUTTON_CLICKED = {
  id: "009",
  type: "Reviews-Minimize-Button-Clicked",
};
export const NUDGES_CLOSED_BY_USER = {
  id: "010",
  type: "Nudges-Closed-By-User",
};
export const CLIENT_INIT_DATA = {
  id: "100",
  type: "Client-Init-Data",
};
export const CLIENT_PRODUCT_NAVIGATION = {
  id: "101",
  type: "Client-Product-Navigation",
};

// click stream events
export const BOT_OPEN = "shoppingassistant_open"; //this
export const MODULE_LOADED_DISCOVERIST = "module_loaded_discoverist"; //this
export const MODULE_CLICKED_NEXT = "module_clicked_next"; //this
export const MODULE_CLICKED_PREV = "module_clicked_prev"; //this
export const PRODUCT_CLICKED = "product_clicked"; //this
export const PRODUCT_MENU_CLICKED = "product_menu_clicked";
export const PRODUCT_MENU_ITEM_CLICKED_SIMILAR_PRODUCTS =
  "product_menu_item_clicked_similar_products";
export const PRODUCT_MENU_ITEM_CLICKED_BUNDLE_IDEAS =
  "product_menu_item_clicked_bundle_ideas";
export const PRODUCT_MENU_ITEM_CLICKED_SPOTLIGHT_PRODUCT =
  "product_menu_item_clicked_spotlight_product";
export const PRODUCT_ADDED_TO_CART_CLICKED = "product_added_to_cart_clicked"; //this
export const PRODUCT_VRIANT_ADDED_TO_CART = "product_variant_added_to_cart";
export const SHARE_BUTTON_CLICKED = "share_button_clicked";
export const SHARE_OPTION_CLICKED_WHATSAPP = "share_option_clicked_whatsApp";
export const SHARE_OPTION_CLICKED_EMAIL = "share_option_clicked_email";
export const SHARE_OPTION_CLICKED_FACEBOOK = "share_option_clicked_facebook";
export const SHARE_OPTION_CLICKED_COPY_LINK = "share_option_clicked_copy_link";
export const SHARE_OPTION_CLICKED_TWITTER = "share_option_clicked_twitter";
export const EVENT_SOURCE = "shoppingassistant"; //this
export const NUDGES_INITIALIZED = "nudges_initialized";
export const NUDGES_QUESTION_CLICKED = "nudges_question_clicked";

export const DEFAULT_QUESTION = [
  "Can you help me find a new pair of jeans?",
  "Im going to a formal dinner, can you help?",
  "What types of dresses do you have?",
  "It's gonna rain soon, got any jackets for me?",
  "Do you guys have anything for warm weather?",
];

export const SHOPIFY_PAGE_TEMPLATES = {
  "page.contact": "Contact",
  contact: "Contact",
  "page.faq": "FAQ",
  faq: "FAQ",
  index: "Home",
  collection: "Collection",
  product: "Product",
  cart: "ShoppingCart",
};
