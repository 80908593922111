import React, { useState, useEffect } from "react";
import "../styles/variantModal.css";
import {
  ADD_TO_CART,
  EVENT_SOURCE,
  PRODUCT_VRIANT_ADDED_TO_CART,
} from "../constants";
import { useVerification } from "../context/VerificationContext";

const VariantModal = ({ onClose, options, product, variants }) => {
  if (variants?.length === 1) {
    if (variants[0]?.title === "Default Title") {
      variants[0].option1 = product?.text;
      options[0].values[0] = product?.text;
    }
  }
  const { sendCustomEvent, isTestEvent } = useVerification();
  const [selectedOptions, setSelectedOptions] = useState(
    options.reduce((acc, option) => {
      acc[option.name] = option.values[0];
      return acc;
    }, {})
  );
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [inStock, setInStock] = useState(false);

  const handleOptionChange = (optionName, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [optionName]: value,
    }));
  };

  const findMatchingVariant = () => {
    const matchingVariant = variants.find((variant) => {
      return Object.keys(selectedOptions).every((optionName, index) => {
        return variant[`option${index + 1}`] === selectedOptions[optionName];
      });
    });

    setSelectedVariant(matchingVariant || null);

    if (matchingVariant) {
      if (
        matchingVariant.inventory_policy === "continue" ||
        (matchingVariant.inventory_policy === "deny" &&
          matchingVariant.inventory_quantity > 0)
      ) {
        setInStock(true);
      } else {
        setInStock(false);
      }
    } else {
      setInStock(false);
    }
  };

  const handleCart = () => {
    if (window.parent?.postMessage) {
      window.parent.postMessage(
        {
          id: ADD_TO_CART.id,
          type: ADD_TO_CART.type,
          discoveristData: {
            product: selectedVariant,
            hrefData: {},
            review: {},
            search: {},
          },
        },
        "*"
      );
    }
    sendCustomEvent(PRODUCT_VRIANT_ADDED_TO_CART, {
      event_source: EVENT_SOURCE,
      products: [
        {
          product_id: product.id,
          product_name: product.text,
          product_price: product.price,
        },
      ],
      is_testevent: isTestEvent,
    });
  };

  useEffect(() => {
    findMatchingVariant();
  }, [selectedOptions]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">
          &times;
        </button>

        <h5 className="product-title">{product.text}</h5>

        <div className="modal-body">
          <div className="image-container">
            <img
              src={product.url}
              alt={product.text}
              className="product-image"
            />
          </div>
          <div className="product-details">
            <p className="product-price">
              ${selectedVariant ? selectedVariant.price : product.price} USD
            </p>
            <p className="in-stock" style={{ color: inStock ? "gray" : "red" }}>
              {inStock ? "In Stock" : "Out Of Stock"}
            </p>

            <div className="options-container">
              {options.map((option) => (
                <div key={option.name} className="option-group">
                  <h5>{option.name}</h5>
                  <div className="option-list">
                    {option.values.map((value) => (
                      <label key={value} className="option-label">
                        <input
                          type="radio"
                          name={option.name}
                          value={value}
                          checked={selectedOptions[option.name] === value}
                          onChange={() =>
                            handleOptionChange(option.name, value)
                          }
                          className="option-input"
                        />
                        <span
                          className={`option-button ${
                            selectedOptions[option.name] === value
                              ? "selected"
                              : ""
                          }`}
                        >
                          {value}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="cart-button-container">
          <button
            className="cart-button"
            disabled={!inStock}
            onClick={handleCart}
            style={{ backgroundColor: inStock ? "#007bff" : "gray" }}
          >
            {inStock ? "Add to Cart" : "Out of Stock"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VariantModal;
