import React, { useEffect, useRef } from "react";
import ImageList from "./ImageList";
// import PoweredByDiscoveristLogo from "../assets/images/Discoverist-logo.webp";
import { useVerification } from "../context/VerificationContext";
import Loader from "./Loader";
import { Chevron } from "./icons/Chevron";
import { ResetIcon } from "./icons/ResetIcon";
import OrderDetails from "./OrderStatus";
import { DEFAULT_QUESTION } from "../constants";
const PoweredByDiscoveristLogo =
  " https://cdn.discoverist.ai/images/Discoverist-logo.webp";
function Modal(props) {
  const {
    messages,
    question,
    isGeneratingChat,
    isChatReady,
    sendChatReq,
    clearChatHistory,
    setContext,
    OGquestion,
    setOGquestion,
    removeContext,
    isContextSet,
    prodContext,
    handleDockButtonClose,
    add2cart,
    toggleChatBox,
    isUserChatSent,
    setIsUserChatSent,
  } = props;


 

  const chatContainerRef = useRef(null);
  const { verified, errorMessage, verifyUser, clientId, settings, isLoading } =
    useVerification();

  const scrollToBottom = () => {
    if (chatContainerRef.current?.scrollTop) {
      chatContainerRef.current.scrollTop =
        chatContainerRef?.current?.scrollHeight;
    }
  };

  const isProdContext = isContextSet
    ? !prodContext.text || prodContext.text === "none"
      ? ""
      : prodContext.text
    : "";

  const { bot_styles, domains, questions, size } = settings;

  const defaultQuestion = DEFAULT_QUESTION

  const getContext = (value) => {
    return value !== "none" && value !== null ? value || isProdContext : false;
  };
  

  const startUpQuestionArray =
    questions?.default?.length > 0
      ? messages.length === 0 &&
        isProdContext &&
        questions.product_context?.length > 0
        ? questions.product_context
        : questions?.default
      : defaultQuestion;

  useEffect(() => {
    scrollToBottom();
  }, [messages, isGeneratingChat, toggleChatBox]);

  return (
    <div
      id="modal"
      style={{
        display: toggleChatBox,
        backgroundColor: "white",
        padding: "17px",
      }}
    >
      <div
        className="parent"
        style={{
          backgroundColor: bot_styles?.header.bg_color ?? "white",
          display: "flex",
          alignItems: "center",
          padding: "3px 10px",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      >
        <div
          style={{
            float: "left",
            textDecoration: "none",
            fontSize: "18.5px",
            color: "rgb(29, 29, 29)",
            fontWeight: "bold",
          }}
          type="button"
          onClick={handleDockButtonClose}
        >
          <Chevron />
        </div>
        <span className="middle">
          {bot_styles?.header.logo_image?.includes("http") && (
            <img
              src={bot_styles?.header.logo_image}
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                height: `${bot_styles?.header.logo_height ?? 45}px`,
              }}
              alt="Home"
            />
          )}
        </span>
        <span
          style={{
            float: "right",
            textDecoration: "none",
            marginRight: "inherit",
          }}
          type="button"
        >
          <button
            onClick={clearChatHistory}
            disabled={isGeneratingChat}
            type="button"
            className="btn btn-light"
            style={{
              width: "50px",
              height: "47.5px",
              borderRadius: "50%",
            }}
          >
            <ResetIcon />
          </button>
        </span>
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "60vh",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div>
            <Loader />
            <button
              style={{
                marginTop: "75px",
                border: "none",
                background: "none",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={handleDockButtonClose}
            >
              Close
            </button>
          </div>
        </div>
      ) : verified ? (
        <>
          <div
            id="modalCardContainer"
            style={{
              paddingTop: "10px",
              marginTop: "5px",
              height: "calc(100vh - 160px)",
              backgroundColor: "white",
            }}
            ref={chatContainerRef}
          >
            <div id="Segment">
              <div
                id="answerSegment"
                style={{ margin: "0px", paddingBottom: "0px" }}
              >
                <div className="imessage" style={{ width: "90%" }}>
                  <p
                    className="from-them"
                    style={{
                      backgroundColor:
                        bot_styles?.bot_message.bot_message_bg_color ?? "#444",
                      color:
                        bot_styles?.bot_message.bot_message_text_color ?? "#fff",
                      fontSize: bot_styles?.font_style.font_size ?? "16px",
                      fontFamily:
                        bot_styles?.font_style.font_family ??
                        "Inter, sans-serif",
                    }}
                  >
                    {messages.length === 0 && isProdContext ? (
                      <div>
                        Hi, I am your shopping assistant! Ask me anything about
                        the
                        <span
                          style={{
                            backgroundColor: "rgba(185, 225, 55, 0.7)",
                            fontFamily:
                              bot_styles?.font_style.font_family ??
                              "Inter, sans-serif",
                            padding: "3px 8px",
                            borderRadius: "6px",
                            marginRight: "5px",
                          }}
                        >
                          {isProdContext}
                        </span>
                      </div>
                    ) : (
                      "Hi, I am your shopping assistant! How can I help you?"
                    )}
                  </p>
                </div>
              </div>

              <div
                id="answerSegment"
                style={{ margin: 0, padding: 0, float: "left" }}
                hidden={isGeneratingChat || messages.length > 0}
              >
                <div className="imessage" style={{ minWidth: "100%" }}>
                  <div
                    className="from-me"
                    style={{
                      minWidth: "92%",
                      maxWidth: "95%",
                      backgroundColor:
                        bot_styles?.bot_message.user_message_bg_color ?? "#ccc",
                      color:
                        bot_styles?.bot_message.user_message_text_color ??
                        "#000",
                      fontSize: bot_styles?.font_style.font_size ?? "16px",
                      fontFamily:
                        bot_styles?.font_style.font_family ??
                        "Inter, sans-serif",
                    }}
                  >
                    {startUpQuestionArray.map((questionText, i) => (
                      <div
                        key={i}
                        type="button"
                        className="startUpQuestion"
                        style={{
                          width: "100%",
                          borderRadius: 0,
                          fontFamily:
                            bot_styles?.font_style.font_family ??
                            "Inter, sans-serif",
                          padding: `${
                            i === 0 ? "5px 0 11px 5px" : "20px 0 11px 5px"
                          }`,
                          height: "auto",
                          borderBottom: `${
                            i === startUpQuestionArray.length - 1
                              ? "0px solid"
                              : "1px solid "
                          }`,
                        }}
                        id="options"
                        onClick={() =>
                          isChatReady ? sendChatReq(`${questionText} `) : ""
                        }
                      >
                        {questionText}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div>
                {messages.map((message, index) => {
                
                  const dataToDisplay =
                    typeof message.answer == "string"
                      ? message.answer
                      : message?.answer?.result;
                  return (
                    <div key={index}>
                      <div
                        id="questionSegment"
                        style={{ margin: 0, paddingBottom: 0 }}
                      >
                        <div className="imessage">
                          <p
                            className="from-me"
                            style={{
                              backgroundColor:
                                bot_styles?.user_message.user_message_bg_color ??
                                "#ccc",
                              color:
                                bot_styles?.user_message
                                  .user_message_text_color ?? "#000",
                              fontSize:
                                bot_styles?.font_style.font_size ?? "16px",
                              fontFamily:
                                bot_styles?.font_style.font_family ??
                                "Inter, sans-serif",
                              lineHeight: "1.5em",
                            }}
                          >
                            {getContext(message?.context?.text) && (
                              <span
                                style={{
                                  backgroundColor: "rgba(185, 225, 55, 0.7)",
                                  fontFamily:
                                    bot_styles?.font_style.font_family ??
                                    "Inter, sans-serif",
                                  padding: "3px 8px",
                                  borderRadius: "6px",
                                  marginRight: "5px",
                                }}
                              >
                                {message?.context?.text}
                              </span>
                            )}
                            {message.question}
                          </p>
                        </div>
                      </div>

                      <div
                        id="answerSegment"
                        style={{ margin: 0, paddingBottom: 0 }}
                      >
                        <div className="imessage">
                          <p
                            className="from-them"
                            style={{
                              backgroundColor:
                                bot_styles?.bot_message.bot_message_bg_color ??
                                "#ccc",
                              color:
                                bot_styles?.bot_message.bot_message_text_color ??
                                "#000",
                              fontSize:
                                bot_styles?.font_style.font_size ?? "16px",
                              fontFamily:
                                bot_styles?.font_style.font_family ??
                                "Inter, sans-serif",
                            }}
                          >
                            {typeof message?.answer == "string" ? (
                              message?.answer
                            ) : (
                              <>
                                {message?.answer[0] === undefined
                                  ? "Please Try after some time"
                                  : message?.answer[0]}
                                {message?.answer[1][0] ? (
                                  <OrderDetails
                                    order={message?.answer[1][0]}
                                    trackColor={
                                      bot_styles?.user_message
                                        .user_message_bg_color ?? "#ccc"
                                    }
                                    trackTextColor={
                                      bot_styles?.user_message
                                        .user_message_text_color ?? "#000"
                                    }
                                  />
                                ) : (
                                  "Please Try after some time"
                                )}
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                      {!message?.answer[1][0].hasOwnProperty("Order ID") && (
                        <ImageList
                          message={message}
                          isGeneratingChat={isGeneratingChat}
                          sendChatReq={sendChatReq}
                          setContext={setContext}
                          prodContext={prodContext}
                          isContextSet={isContextSet}
                          add2cart={add2cart}
                          font_family={bot_styles?.font_style.font_family}
                          isUserChatSent={isUserChatSent}
                          setIsUserChatSent={setIsUserChatSent}
                          canCallModuleLoadEvent={(() => {
                            return index === messages.length - 1
                              ? message.hasOwnProperty("products") &&
                                  message.products.length > 0
                              : false;
                          })()}
                        />
                      )}
                    </div>
                  );
                })}
                <div
                  id="Segment"
                  style={{ display: isGeneratingChat ? "block" : "none" }}
                >
                  {isGeneratingChat && (
                    <div
                      id="questionSegment"
                      style={{ margin: "0px", paddingBottom: "0px" }}
                    >
                      <div className="imessage">
                        <p
                          className="from-me"
                          style={{
                            backgroundColor:
                              bot_styles?.user_message.user_message_bg_color ??
                              "#ccc",
                            color:
                              bot_styles?.user_message.user_message_text_color ??
                              "#000",
                            fontSize: bot_styles?.font_style.font_size ?? "16px",
                            fontFamily:
                              bot_styles?.font_style.font_family ??
                              "Inter, sans-serif",
                            lineHeight: "1.5em",
                          }}
                        >
                          <span className="highlight-container">
                            {isProdContext && (
                              <span
                                className="highlight"
                                id="titles"
                                style={{
                                  backgroundColor: "rgba(185, 225, 55, 0.7)",
                                  fontFamily:
                                    bot_styles?.font_style.font_family ??
                                    "Arial",
                                  padding: "3px 8px",
                                  borderRadius: "6px",
                                  marginRight: "5px",
                                }}
                              >
                                {`${isProdContext}`}
                              </span>
                            )}
                          </span>
                          {question}
                        </p>
                        {/* <p
                          className="from-me"
                          style={{
                            display: isContextSet ? "none" : "block",
                            backgroundColor: user_message_bg_color ?? "rgb(22, 51, 210)",
                            color: user_message_text_color ?? "rgb(255, 255, 255)",
                            fontSize: font_size ?? "16px",
                            fontFamily: font_family ?? "Inter, sans-serif",
                          }}
                        >
                          {question}aaaaa
                        </p> */}
                      </div>
                    </div>
                  )}
                  {isGeneratingChat && (
                    <div
                      id="answerSegment"
                      style={{ margin: "0px", paddingBottom: "0px" }}
                    >
                      <div
                        className="imessage"
                        style={{ width: "90%", padding: "0px" }}
                      >
                        <p
                          className="from-them loading"
                          style={{
                            backgroundColor:
                              bot_styles?.bot_message.bot_message_bg_color ??
                              "#ccc",
                          }}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                position: "fixed",
                bottom: "2em",
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingBottom: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                backgroundColor: "#fff",
              }}
            >
              <input
                disabled={!isChatReady}
                id="demoE"
                placeholder={
                  isProdContext ? `Ask about ${isProdContext}` : `Type here`
                }
                type="text"
                name="topic"
                required
                className="form-control"
                value={OGquestion}
                onChange={(e) => setOGquestion(e.target.value)}
                autoCapitalize="sentences"
                autoComplete="off"
                autoFocus={true}
                spellCheck={true}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendChatReq(OGquestion);
                  }
                }}
                style={{
                  borderBottomLeftRadius: "10px",
                  borderTopLeftRadius: "10px",
                  fontFamily:
                    bot_styles?.font_style.font_family ?? "Inter, sans-serif",
                }}
                maxLength="200"
              />

              <button
                disabled={isGeneratingChat}
                type="button"
                className="btn btn-light"
                onClick={() => sendChatReq(OGquestion)}
                id="askQuestionBox"
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  borderBottomRightRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <i
                  style={{ fontSize: "16px" }}
                  className="fa-regular fa-paper-plane"
                ></i>
              </button>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: ".5em",
              paddingBottom: "10px",
              backgroundColor: "#fff",
              fontSize: "11px",
              color: "#999",
            }}
          >
            <div style={{ paddingTop: "8px" }}>Powered by</div>
            <a
              href="https://discoverist.ai?ref=shopping-assistant"
              target="_blank"
              rel="noreferrer"
              aria-label="powered by discvoerist"
            >
              <img src={PoweredByDiscoveristLogo} height={12} />
            </a>
          </div>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "60vh",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            color: "red",
          }}
        >
          <p style={{ margin: 0 }}>{errorMessage}</p>
          <button
            style={{
              border: "none",
              background: "none",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={() => verifyUser(clientId)}
          >
            Verify
          </button>
          <button
            style={{
              border: "none",
              background: "none",
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={handleDockButtonClose}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default Modal;
