import React, { createContext, useContext, useState, useEffect } from "react";
import Tracker from "../components/Tracker";
import { SHOPIFY_PAGE_TEMPLATES } from "../constants";

const VerificationContext = createContext();

const DEFAULT_SETTINGS = {
  domains: {
    whitelist: ["discoverist-dashboard-test-9.myshopify.com"],
  },
  bot_styles: {
    header: {
      logo_image: "/build/_assets/chatLogo2-HMW3D76E.png",
      logo_height: "",
      bg_color: "",
    },
    bot_message: {
      bot_message_bg_color: "",
      bot_message_text_color: "",
    },
    user_message: {
      user_message_bg_color: "",
      user_message_text_color: "",
    },
    font_style: {
      font_size: "",
      font_family: "",
    },
    icon_style: {
      icon_height: "",
      icon_shape: "",
      icon_color: "",
    },
    product_config: {
      add_to_cart_bg_color: "",
    },
  },
  size: {
    iframe: {
      height: "",
      width: "",
    },
  },
  questions: {
    default: [
      "Can you help me find a new pair of jeans?",
      "Im going to a formal dinner, got anything?",
      "What types of dresses do you carry?",
      "It's gonna rain soon, got any jackets for me?",
      "Do you guys have anything for warm weather?",
    ],
    product_context: ["This is a product question"],
  },
  nudges_options: {
    status: "active",
  },
};

export const useVerification = () => {
  return useContext(VerificationContext);
};

export const VerificationProvider = ({ children }) => {
  const [verified, setVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clientId, setClientId] = useState("");
  const [assistantMode, setAssistantMode] = useState("");
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [isLoading, setIsLoading] = useState(true);
  const [user_id, setUser_id] = useState("");
  const [nudgesPayload, setNudgesPayload] = useState({});
  const [productContext, setProductContext] = useState({
    id: null,
    name: null,
    price: null,
  });
  const [isTestEvent, setIsTestEvent] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [pageType, setPageType] = useState("");

  const verifyUser = async (clientId) => {
    setVerified(true);
  };

  const initializeTracker = (clientId) => {
    if (typeof discoveristTracker !== "undefined") {
      window.discoveristTracker.initializeTracker(
        clientId,
        "1Z93CvAUmG3LfQ5yKUgqs7PHLLZBCNjR2uq21Y8p"
      );
    } else {
      console.error("discoveristTracker is not defined");
    }
  };

  const sendCustomEvent = (eventName, eventData) => {
    if (typeof discoveristTracker !== "undefined") {
      window.discoveristTracker.sendEvent(eventName, {
        ...eventData,
        user_id: user_id,
      });
    } else {
    }
  };

  const handleIframeEvent = (event) => {
    const data = event.data;
    if (data?.type === "shopping_assistant") {
      setSettings(data.shopping_assistant);
    } else {
      setSettings(DEFAULT_SETTINGS);
    }
  };

  useEffect(() => {
    async function load() {
      window.addEventListener("message", handleIframeEvent);
      const queryString = window.location.search;
      const pathParts = window.location.pathname.split("/");
      let clientId = pathParts[3] === "clients" ? pathParts[4] : null;
      const urlParams = new URLSearchParams(queryString);
      const getAssistantMode = urlParams.get("assitant_mode");
      if (getAssistantMode) {
        if (
          getAssistantMode == "faq_search" ||
          getAssistantMode == "reviews_search"
        ) {
          setAssistantMode(getAssistantMode);
          setIsLoading(false);
        }
      }
      const getUser_id = urlParams.get("user_id");

      if (getUser_id !== null && getUser_id !== undefined) {
        setUser_id(getUser_id);
      }
      if (!clientId) {
        clientId = urlParams.get("clientid");
        setClientId(clientId);
        initializeTracker(clientId);
      }
      if (urlParams.get("productid") && urlParams.get("productname")) {
        const id = urlParams.get("productid");
        const name = urlParams.get("productname");
        const price = urlParams.get("productprice");
        setProductContext({ id, name, price });
      }

      if (urlParams.get("pagetemplate")) {
        const data = {};
        const pageTemplate = urlParams.get("pagetemplate");
        if (pageTemplate === "cart") {
          if (urlParams.get("cartData")) {
            const getCartData = urlParams.get("cartData");
            setCartData(JSON.parse(getCartData));
            data["cartata"] = JSON.parse(getCartData);
          }
        }
        if (urlParams.get("collectiontitle")) {
          const getCollection = urlParams.get("collectiontitle");
          setCollectionTitle(getCollection);
          data["collection_name"] = getCollection;
        }
        if (urlParams.get("productid")) {
          const id = urlParams.get("productid");
          const name = urlParams.get("productname");
          const price = urlParams.get("productprice");
          const product = {
            id,
            name,
            price,
          };
          data["product"] = product;
        }
        setPageType(SHOPIFY_PAGE_TEMPLATES[pageTemplate]);
        setNudgesPayload(data);
      }
    }
    load();
  }, []);

  useEffect(() => {
    if (clientId) {
      verifyUser(clientId);
      setIsLoading(false);
    }
  }, [settings, clientId]);

  if (isLoading) return <></>;

  return (
    <VerificationContext.Provider
      value={{
        verified,
        errorMessage,
        verifyUser,
        settings,
        productContext,
        setProductContext,
        clientId,
        sendCustomEvent,
        isLoading,
        isTestEvent,
        assistantMode,
        user_id,
        cartData,
        collectionTitle,
        pageType,
        nudgesPayload,
      }}
    >
      {Object.keys(settings).length !== 0 && settings ? children : "Error"}
      {settings?.client_id && <Tracker clientId={settings.client_id} />}
    </VerificationContext.Provider>
  );
};
